"use client";

import { PhoneIcon } from "@/components/icons";
import { BNHPhone } from "@/components/navbar";
import { openResaleListingModal } from "@/components/resale-unit-form";
import { Phone } from "lucide-react";
import Image from "next/image";
import Link from "next/link";

export function NavbarComponent() {
  const onPostYourPropertyClick = () => {
    openResaleListingModal({ source: "post_property_homepage" });
  };

  return (
    <nav className="bg-background-400 bg-opacity-90 border-gray-300 border-b md:border md:border-gray-100 md:shadow-md md:rounded-xl">
      <div className="max-w-7xl mx-auto p-2">
        <div className="flex items-center justify-between gap-4 md:gap-16 w-full">
          {/* Logo */}
          <div className="flex-shrink-0 flex items-center gap-1 md:gap-2">
            <Image
              className="h-6 md:h-8 w-auto"
              src="/logos/bnHabitatLogo.svg"
              alt="Habitat"
              width={200}
              height={60}
            />
            <div className="font-bold text-xs md:text-2xl text-primary-900">
              BN Habitat
            </div>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center justify-end gap-2 flex-1">
            {/* Post Property Button */}
            <button
              onClick={onPostYourPropertyClick}
              className="flex-shrink-0 px-12 py-3.5 text-sm rounded-md text-primary-50 bg-primary-950 hover:bg-primary-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              style={{
                boxShadow:
                  "0px 4px 8px 0px rgba(45, 32, 17, 0.20), 0px 1.5px 3px 0px rgba(45, 32, 17, 0.20), 0px 1px 1px 0px rgba(45, 32, 17, 0.12), 0px -3px 0px 0px rgba(0, 0, 0, 0.75) inset",
              }}
            >
              Post property
            </button>

            {/* Call Us */}
            <Link
              href={`tel:${BNHPhone}`}
              className="flex items-center gap-2 text-primary font-medium flex-shrink-0 px-5 py-3.5 border border-primary-400 text-sm rounded-md bg-background-100 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 rounded-lg"
              style={{
                boxShadow:
                  "0px -2px 0px 1px #2D201114 inset, 0px 1px 2px 0px #2D20110A",
              }}
            >
              <PhoneIcon className="flex-shrink-0 h-5 w-5" />
              <span>{BNHPhone} </span>
            </Link>
          </div>

          <div className="md:hidden flex gap-2">
            <button
              onClick={onPostYourPropertyClick}
              className="flex-shrink-0 px-8 py-3 text-sm rounded-md text-primary-50 bg-primary-950 hover:bg-primary-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              style={{
                boxShadow:
                  "0px 4px 8px 0px rgba(45, 32, 17, 0.20), 0px 1.5px 3px 0px rgba(45, 32, 17, 0.20), 0px 1px 1px 0px rgba(45, 32, 17, 0.12), 0px -3px 0px 0px rgba(0, 0, 0, 0.75) inset",
              }}
            >
              Post property
            </button>
            <div className="h-12 border-r border-gray-300 md:hidden" />
            <Link
              href={`tel:${BNHPhone}`}
              className="border border-gray-300 md:border-none flex items-center justify-center p-2 md:p-3 hover:bg-gray-100 active:bg-gray-300 rounded-lg md:rounded-full"
            >
              <Phone className="flex-shrink-0 h-6 w-6 text-primary" />
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}
